<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img height="42px" class="c-sidebar-brand-full sidebar-logo" src="logo-dark.svg" alt="">
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard"></CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4" v-if="username">
      <CHeaderNavItem class="d-md-down-none mx-2">
        Hello, <b>{{username}}</b>! <a href="#" class="logout-link" @click.prevent="signOut()">Logout</a>
      </CHeaderNavItem>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: 'TheHeader',
  components: {},
  data() {
    return {
      username: null
    }
  },
  methods: {
    signOut() {
      Auth.signOut().then(() => {
        this.$router.push('/user/login');
      }).catch((err) => {
        console.log(err);
      });
    }
  },
  async created() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.username = user.attributes.email.split('@').shift();
    } catch (err) {
      console.log(err);
    }
  }
}
</script>

<style lang="scss">
.logout-link {
  color: rgba(0,0,21,.5);
  text-decoration: none !important;

  &:hover {
    color: rgba(0,0,21,1)
  }
}
</style>