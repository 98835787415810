export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Search',
        to: '/admin/search',
        icon: 'cil-zoom',
        allowedGroups: ['admin']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Statistics',
        to: '/stats',
        icon: 'cil-chart-line',
        allowedGroups: ['admin']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Domains',
        to: '/domain',
        icon: 'cil-browser',
        allowedGroups: ['user']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Profile',
        to: '/profile',
        icon: 'cil-user',
        allowedGroups: ['user']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Invoices',
        to: '/invoices',
        icon: 'cil-cash',
        allowedGroups: ['user']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Access Control',
        to: '/acl',
        icon: 'cil-lock-locked',
        allowedGroups: ['user']
      }
    ]
  }
]
