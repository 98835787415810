<template>
  <CFooter :fixed="false">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-sm">
          <span class="ml-1">&copy; {{new Date().getFullYear()}} Atlantic Silicon Inc.</span>
        </div>
        <div class="col-sm text-center">
          <span>3999 Commons Dr. W Unit N, Destin, FL 32541</span>
          <div class="footer-center-links">
            <a href="https://www.limitloginattempts.com/terms/" target="_blank">terms and conditions</a>
            <a href="https://www.limitloginattempts.com/privacy-policy/" target="_blank">privacy policy</a>
          </div>
        </div>
        <div class="col-sm text-right">
          <a href="mailto:sales@limitloginattempts.com">sales@limitloginattempts.com</a>
        </div>
      </div>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style lang="scss">
.footer-center-links {
  display: flex;
  justify-content: space-between;
}
</style>
